import request from './vendor/request';

interface PrivateAccessToken {
    id: number;
    github_name: string | null;
    github_token: string | null;

    discord_name: string | null;
    discord_token: string | null;

    twitter_name: string | null;
    twitter_token: string | null;

    matrix_name: string | null;
    matrix_token: string | null;

    identity_public_key: string;
    created_at: string;
    updated_at: string;
}

export default {
    getSocials: (identityId: string) =>
        request.get<PrivateAccessToken>(`/api/accesstoken/${identityId}`),
    putSocial: (wallet: string, provider: string, token: string) =>
        request.put<PrivateAccessToken>(
            `/api/accesstoken/${wallet}/${provider}/${token}`,
            {},
        ),
    deleteSocial: (identityId: string, provider: string) =>
        request.deleteSocial<PrivateAccessToken>(
            `/api/accesstoken/${provider}/${identityId}`,
            {},
        ),
};
