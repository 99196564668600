import { AvailableSocialsI } from '@/interfaces/Identity.interface';
import { AccountProvider } from './interface/connectAccount';
import request from './vendor/request';

export default {
    getProviders: (identityId: string) =>
        request.get<AccountProvider[]>('/api/connect/providers/' + identityId),
    getProvider: (id: string) =>
        request.get<AccountProvider>('/api/connect/providers/' + id),
    getUserByToken: (token: string, provider: AvailableSocialsI) =>
        request.get<any>(`/api/connect/users/${provider}/${token}`),
    getExtrinsic: (network: string, hash: string) =>
        request.get<any>(`/api/extrinsic/${network}/${hash}`),
    getBalances: (network: string, id: string) =>
        request.get<any>(`/api/balances/${network}/${id}`),
};
