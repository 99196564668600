import Axios from 'axios';

const axios = Axios.create({
    // baseURL: process.env.NEXT_PUBLIC_BACKEND_URL,
    baseURL: '/', // TODO: add backend url on .env
    headers: {
        'X-Requested-With': 'XMLHttpRequest',
    },
    withCredentials: true,
    withXSRFToken: true,
});

window.axios = axios;
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

export default axios;
