import adapters from '@/adapters';
import api from '@/adapters/api';
import {
    AvailableSocialsI,
    IdentityI,
    IdentityKeyData,
    IdentityPolkaI,
} from '@/interfaces/Identity.interface';
import { ProviderControllerI } from '@/interfaces/Providers.interfaces';
import { WalletBalanceI, WalletI } from '@/interfaces/Wallet.interfaces';
import cookie from '@/utils/cookie';
import { inject } from '@mimirdev/apps-inject';
import { ApiPromise, WsProvider } from '@polkadot/api';
import { hexToU8a, u8aToHex } from '@polkadot/util';
import { Keyring } from '@polkadot/keyring';
import {
    web3Accounts,
    web3Enable,
    web3FromSource,
} from '@polkadot/extension-dapp';
import type { InjectedAccountWithMeta } from '@polkadot/extension-inject/types';
import { AccountId32, ExtrinsicStatus } from '@polkadot/types/interfaces';
import BigNumber from 'bignumber.js';
import connectAccount from '@/adapters/api/connectAccount';
import { blake2AsHex, validateAddress } from '@polkadot/util-crypto';
import { Data, Option } from '@polkadot/types';
import { SubIdentityEntry } from '@/Components/modals/SubIdentity/SubIdentity';
import { Session } from 'inspector';

// u can insert logical data login here
interface ConnectWalletInterface {
    network: SocketTypes;
    subIdentities: SubIdentityEntry[];
    walletExtension: string;
    currentWalletAddress: WalletI;
    walletBalance: WalletBalanceI;
    requestJudgementExtrinsicHash: string;
    multiSigThreshold: number | null;
    multiSigPendingSignatures: number | null;
    currentProvider: SocketTypes;
    identityPolka: IdentityPolkaI;
    identitySignatureStatus: ExtrinsicStatus;
    userProfile: IdentityI;
    userSocials: Partial<Record<AvailableSocialsI, string | null>>;
}

const socketProviders = {
    polkadot: {
        relay: 'wss://rpc.polkadot.io',
        people: 'wss://polkadot-people-rpc.polkadot.io',
        ss58: 0,
    },
    kusama: {
        relay: 'wss://kusama-rpc.polkadot.io',
        people: 'wss://kusama-people-rpc.polkadot.io',
        ss58: 2,
    },
};

export type SocketTypes = keyof typeof socketProviders;

export default class ConnectWalletController extends ProviderControllerI<ConnectWalletInterface> {
    // need use init method to initialize this class

    protected peopleApi: ApiPromise | undefined;
    protected relayApi: ApiPromise | undefined;

    protected peopleApiResolve: ((value: unknown) => void) | undefined;
    protected peopleApiPromise: Promise<any> = new Promise(
        (res) => (this.peopleApiResolve = res),
    );

    protected relayApiResolve: ((value: unknown) => void) | undefined;
    protected relayApiPromise: Promise<any> = new Promise(
        (res) => (this.relayApiResolve = res),
    );

    protected profileResolve: ((value: IdentityI) => void) | undefined;
    protected profilePromise: Promise<IdentityI> = new Promise(
        (res) => (this.profileResolve = res),
    );

    protected extensionName = 'PolkaIdentity'; // show when open extension
    private balancePoller: NodeJS.Timeout | undefined;

    async init() {
        this.setValue({});

        const wallet = this.getCurrentWallet();
        const provider = this.getCurrentProvider();

        if (provider) {
            await this.setPeopleApiProvider(provider);
            if (wallet) await this.setWalletAccount(wallet, false);
        }
    }

    async initRelayApi() {
        const provider = this.getCurrentProvider();
        if (provider) {
            await this.setRelayApiProvider(provider);
        }
    }

    async setRelayApiProvider(provider: SocketTypes) {
        this.relayApi = await this.generateApiInstance(
            socketProviders[provider].relay,
        );
        if (this.relayApiResolve) this.relayApiResolve(null);
    }

    async setPeopleApiProvider(provider: SocketTypes) {
        this.saveCurrentProvider(provider);

        this.peopleApi = await this.generateApiInstance(
            socketProviders[provider].people,
        );
        if (this.peopleApiResolve) this.peopleApiResolve(null);
    }

    setWalletExtension(walletExtension: string) {
        this.setValue({ walletExtension });
    }

    getWalletExtension() {
        return this.value?.walletExtension ?? null;
    }

    setProfileAsPending() {
        this.profilePromise = new Promise((res) => (this.profileResolve = res));
    }
    onProfileIsReady() {
        return this.profilePromise;
    }
    resolveProfile(identity: IdentityI) {
        if (this.profileResolve) this.profileResolve(identity);
    }

    onPeopleApiIsReady() {
        return this.peopleApiPromise;
    }

    onRelayApiIsReady() {
        return this.relayApiPromise;
    }


    apiInstanceIsReady() {
        return !!this.peopleApi;
    }

    generateApiInstance(providerUrl: string) {
        const wsProvider = new WsProvider(providerUrl);
        return ApiPromise.create({ provider: wsProvider });
    }

    getCurrentAccount() {
        return this.value?.currentWalletAddress;
    }

    haveAccountConnected() {
        return !!this.getCurrentWallet();
    }

    saveCurrentWallet(wallet: WalletI) {
        cookie.set('current-wallet', JSON.stringify(wallet));
    }
    saveCurrentProvider(data: SocketTypes) {
        if (data in socketProviders) {
            cookie.set('current-provider', data);
            this.setValue({ currentProvider: data });
        }
    }
    saveCurrentIdentityId(id: string) {
        cookie.set('identity-id', id);

    }
    deleteCurrentIdentityId() {
        cookie.delete('identity-id');
    }

    deleteCurrentWallet() {
        cookie.delete('current-wallet');
    }
    deleteCurrentProvider() {
        cookie.delete('current-provider');
    }

    getCurrentWallet(): WalletI | null {
        try {
            const data = cookie.get('current-wallet');
            if (data) return JSON.parse(data);
            return null;
        } catch (error) {
            return null;
        }
    }

    setNetwork(network: SocketTypes) {
        this.saveCurrentProvider(network)
        this.setValue({ network })
    }

    getNetwork() {
        return this.value?.network ?? null
    }
    getCurrentProviderFromMemory() {
        const data = sessionStorage.getItem('current-provider');
        if (data && data in socketProviders) return data as SocketTypes;
    }

    getCurrentProvider(): SocketTypes | null {
        try {
            const currentProvider = this.value.currentProvider;
            if (currentProvider) return currentProvider;

            const data = cookie.get('current-provider');
            if (data && data in socketProviders) return data as SocketTypes;
            return null;
        } catch (error) {
            return null;
        }
    }

    getTransactionCost() {
        const currentProvider = this.getCurrentProvider();
        if (!currentProvider) return;

        const sulfix = this.getCurrentProviderCurrency();

        let value = 0;
        if (currentProvider == 'kusama') value = 0.046;
        if (currentProvider == 'polkadot') value = 0.7;

        return `${value} ${sulfix}`;
    }

    getCurrentProviderSS8() {
        const currentProvider = this.getCurrentProvider();
        if (!currentProvider) return;

        return socketProviders[currentProvider].ss58;
    }
    getCurrentProviderCurrency() {
        const currentProvider = this.getCurrentProvider();
        if (!currentProvider) return;

        if (currentProvider == 'kusama') return 'KSM';
        if (currentProvider == 'polkadot') return 'DOT';
    }
    getRegistrarFee() {
        const currentProvider = this.getCurrentProvider();
        if (!currentProvider) return;

        if (currentProvider == 'kusama') return 0.04;
        if (currentProvider == 'polkadot') return 0.5;
    }
    getMinDeposit() {
        const currentProvider = this.getCurrentProvider();
        if (!currentProvider) return;

        if (currentProvider == 'kusama') return 0.006;
        if (currentProvider == 'polkadot') return 0.2;
    }

    async patchSocials(provider: string, token: string) {
        const identity = await this.onProfileIsReady();
        if (!identity) return;

        const wallet = identity.public_key;

        try {
            const socials = await api.socials.putSocial(
                wallet,
                provider,
                token,
            );
            const socialsObj = {
                discord: socials?.discord_name ?? null,
                twitter: socials?.twitter_name ?? null,
                github: socials?.github_name ?? null,
                matrix: socials?.matrix_name ?? null,
            };

            this.setValue({
                userSocials: socialsObj,
            });
        } catch (err) {
            console.log('err', err);
        }
    }

    getUserSocials() {
        return this.value?.userSocials;
    }

    setWalletAccount(wallet: WalletI, saveState = true) {
        this.setProfileAsPending();

        this.setValue({ currentWalletAddress: wallet });
        const promise = this.setUserProfileByWallet(wallet.address);
        this.subscribeCurrentWalletBalance(wallet);

        if (saveState) this.saveCurrentWallet(wallet);

        promise.then((identity) => {
            this.setValue({
                userSocials: {
                    discord: identity?.discord || null,
                    github: identity?.github || null,
                    matrix: identity?.matrix || null,
                    twitter: identity?.twitter || null,
                },
            });
            if (identity) this.resolveProfile(identity);
        });

        return promise;
    }

    setIdentityPolka(identityPolka: Record<IdentityKeyData, string | null>) {
        this.setValue({ identityPolka });
    }
    getIdentityPolka() {
        return this.value.identityPolka;
    }

    async getAvailableWallets(): Promise<InjectedAccountWithMeta[] | null> {
        try {
            await web3Enable(this.extensionName);
            const ss8 = this.getCurrentProviderSS8();

            if (this.getWalletExtension() === 'mimir') {
                inject();
                return await web3Accounts({ ss58Format: ss8 });
            }

            return await web3Accounts({ ss58Format: ss8, extensions: [this.getWalletExtension()] });
        } catch (error) {
            return null;
        }
    }

    stopBalancePoller() {
        if (!this.balancePoller) return;

        clearInterval(this.balancePoller);
    }

    private async subscribeCurrentWalletBalance(currentWalletAddress: WalletI) {
        clearInterval(this.balancePoller);

        const provider = this.getCurrentProvider() ?? 'polkadot';
        const fetchBalance = () => {
            connectAccount.getBalances(provider, currentWalletAddress.address).then((data) => {
                const relayBalance = BigInt(data[provider]?.free ?? 0);
                const peopleBalance = BigInt(data[`people-${provider}`]?.free ?? 0);

                this.setWalletBalance(relayBalance, peopleBalance);
            });
        };

        fetchBalance();

        this.balancePoller = setInterval(fetchBalance, 6000);
    }

    setWalletBalance(relayBalance: bigint, peopleBalance: bigint) {
        this.setValue({ walletBalance: { relayBalance, peopleBalance } });
    }

    getWalletBalance() {
        return this.value?.walletBalance;
    }

    getHumanRelayBalance() {
        return this.getHumanWalletBalance(this.getWalletBalance()?.relayBalance ?? 0n);
    }

    getHumanPeopleBalance() {
        return this.getHumanWalletBalance(this.getWalletBalance()?.peopleBalance ?? 0n);
    }

    getHumanWalletBalance(balance: bigint) {
        const currentProvider = this.getCurrentProvider();
        if (!currentProvider) return "0";

        if (currentProvider == 'kusama') {
            return BigNumber(balance.toString())
                .div(1000000000000)
                .toString();
        }

        return BigNumber(balance.toString())
            .div(10000000000)
            .toString();
    }

    getMultiSigPendingSignatures() {
        return this.value?.multiSigPendingSignatures ?? 0;
    }

    setMultiSigPendingSignatures(pending: number | null) {
        this.setValue({ multiSigPendingSignatures: pending });
    }

    getMultiSigThreshold() {
        return this.value?.multiSigThreshold ?? 1;
    }

    setMultiSigThreshold(threshold: number | null) {
        this.setValue({ multiSigThreshold: threshold });
    }

    getRequestJudgementExtrinsicHash() {
        return this.value?.requestJudgementExtrinsicHash;
    }

    setRequestJudgementExtrinsicHash(hash: string) {
        this.setValue({ requestJudgementExtrinsicHash: hash });
    }

    getUserProfile() {
        return this.value?.userProfile;
    }

    getProcessCost() {
        const currentProfile = this.getUserProfile();
        const currentBalance = this.getWalletBalance()?.peopleBalance ?? 0n;
        const currentProvider = this.getCurrentProvider();
        if (!currentProfile) return;

        const { deposit } = currentProfile;
        if (!deposit) return;

        let shiftValue = -12;
        if (currentProvider == 'kusama') shiftValue = -12;
        if (currentProvider == 'polkadot') shiftValue = -10;

        const canRequest = deposit
            ? BigNumber(deposit).lte(currentBalance.toString())
            : false;
        const missingCost = deposit
            ? BigNumber(deposit)
                .minus(currentBalance.toString())
                .shiftedBy(shiftValue)
                .toString()
            : undefined;
        const shiftedCost = new BigNumber(parseInt(deposit))
            .shiftedBy(shiftValue)
            .toString();

        if (canRequest) {
            this.stopBalancePoller();
        } else {
            this.subscribeCurrentWalletBalance(this.getCurrentAccount());
        }

        return { canRequest, missingCost, shiftedCost };
    }

    profileToIdentity(profile: IdentityI): Record<IdentityKeyData, string | null> {

        return {
            display: profile.display_name,
            legal: profile.legal_name,
            web: profile.web,
            email: profile.email,
            pgpFingerprint: null, // As it's an Option type, it can be null
            image: profile?.image_id ? profile?.image_id.startsWith('pidp:') ? profile?.image_id : `pidp:${profile?.image_id}` : null,
            twitter: profile.twitter,
            github: profile.github,
            discord: profile.discord,
            matrix: profile.matrix,
            web_verification_hash: profile.web_verification_hash,
            web_verified_at: profile.web_verified_at,
        }

    }

    async setUserProfile(userProfile: IdentityI) {
        try {
            this.setValue({ userProfile });
            this.saveCurrentIdentityId(userProfile.id);
            this.setIdentityPolka(this.profileToIdentity(userProfile));
        } catch (error) {
            this.setValue({
                userProfile: undefined,
                identityPolka: undefined,
                userSocials: undefined,
            });
        }
    }

    setSocials(socials: Record<AvailableSocialsI, string | null>) {
        this.setValue({ userSocials: socials });
        this.setIdentityPolka({ ...this.getIdentityPolka(), ...socials });
    }

    async setCurrentSocialsByIdentityId(identityId: string) {
        const { discord_name, github_name, twitter_name, matrix_name } =
            await api.socials.getSocials(identityId);
        const socials = {
            discord: discord_name,
            github: github_name,
            twitter: twitter_name,
            matrix: matrix_name,
        };
        this.setSocials(socials);

        return socials;
    }

    async setIdentityRequestJudgement(walletAddress: string, hash: string) {
        return new Promise(async (res, rej) => {
            const currentProvider = this.getCurrentProvider();
            if (!currentProvider) return 'Did not find current provider';

            return adapters.api.identity.postRequestJudgementHash(currentProvider, walletAddress, hash).then((data) => {
                return res(data);
            }).catch((err) => {
                return rej(err);
            });
        });
    }

    async setUserProfileByWallet(walletAddress: string) {
        try {
            const currentProvider = this.getCurrentProvider();
            if (!currentProvider) return;

            let userProfile = await adapters.api.identity.getUserProfile(
                walletAddress,
                currentProvider,
            );
            this.setValue({ userProfile });
            this.saveCurrentIdentityId(userProfile.id);

            const socials = await this.setCurrentSocialsByIdentityId(
                userProfile.id,
            );

            userProfile = { ...userProfile, ...socials };
            this.setIdentityPolka(this.profileToIdentity(userProfile));

            return userProfile;
        } catch (error) {
            this.setValue({ userProfile: undefined });
            this.setValue({ identityPolka: undefined });
        }

        return;
    }

    setSubidentities(subidentities: SubIdentityEntry[]) {
        let utf8 = new TextDecoder();

        const subs = subidentities.map((sub: SubIdentityEntry) => {
            const data = JSON.parse(sub.alias);
            if (data.raw) {
                try {
                    let raw = utf8.decode(hexToU8a(data.raw));
                    return { address: sub.address, alias: raw };
                } catch (err) {
                    return { address: sub.address, alias: data.raw };
                }
            }

            if (data.blakeTwo256) return { address: sub.address, alias: data.raw };
            if (data.sha256) return { address: sub.address, alias: data.raw };
            if (data.shaThree256) return { address: sub.address, alias: data.raw };
            if (data.keccak256) return { address: sub.address, alias: data.raw };

            return { address: sub.address, alias: '' };
        })

        this.setValue({ subIdentities: subs });
    }

    getSubidentities(): SubIdentityEntry[] {
        return this.value?.subIdentities;
    }

    async fetchSubidentities() {
        if (!this.peopleApi) return this.onPeopleApiIsReady();
        this.peopleApi = this.peopleApi!;

        const [_, subs] = await this.peopleApi.query.identity.subsOf(this.getCurrentAccount().address);
        const promises: any[] = [];
        subs.forEach((sub) => {
            const promise = this.peopleApi!.query.identity.superOf(sub);
            promises.push(promise);
        });

        const entries: SubIdentityEntry[] = [];
        const superOfs = await Promise.all(promises);
        // @ts-ignore
        superOfs.forEach((superOf: Option<[AccountId32, Data]>) => {
            const [account, data] = superOf.unwrapOrDefault();
            entries.push({
                address: account.toString(),
                alias: data.toString(),
            });
        });

        this.setSubidentities(entries);
    }

    async getBatchFee(
        account: InjectedAccountWithMeta,
        identity: IdentityPolkaI,
    ) {
        if (!this.peopleApi) await this.onPeopleApiIsReady();
        this.peopleApi = this.peopleApi!;

        const parsedIdentity = this.parseIdentity(identity);

        const regIndex = this.getRegIndex();
        const maxFee = this.getMaxFee();

        if (!regIndex || !maxFee) return;

        const setIdentity = this.peopleApi.tx.identity.setIdentity(parsedIdentity);
        const judgment = this.peopleApi.tx.identity.requestJudgement(
            regIndex,
            maxFee,
        );

        return await this.peopleApi.tx.utility
            .batch([setIdentity, judgment])
            .paymentInfo(account.address);
    }

    getExtrinsic(hash: string) {
        const currentNetwork = this.getCurrentProvider() ?? 'polkadot';

        return adapters.api.connectAccount.getExtrinsic(currentNetwork, hash);
    }

    getRegIndex() {
        const currentNetwork = this.getCurrentProvider();

        if (currentNetwork == 'kusama') return 6;
        if (currentNetwork == 'polkadot') return 4;
    }
    getMaxFee() {
        const currentNetwork = this.getCurrentProvider();

        if (currentNetwork == 'kusama') return 40000000000;
        if (currentNetwork == 'polkadot') return 5000000000;
    }
    getTeleportUrl() {
        const currentNetwork = this.getCurrentProvider();

        if (currentNetwork == 'kusama')
            return 'https://polkadot.js.org/apps/?rpc=wss%3A%2F%2Fkusama.api.onfinality.io%2Fpublic-ws#/teleport';
        if (currentNetwork == 'polkadot')
            return 'https://polkadot.js.org/apps/?rpc=wss%3A%2F%2Fpolkadot-rpc.dwellir.com#/teleport';
    }

    async runSubidentities(account: InjectedAccountWithMeta, subidentities: SubIdentityEntry[]) {
        return new Promise(async (res, rej) => {
            if (!this.peopleApi) await this.onPeopleApiIsReady();
            this.peopleApi = this.peopleApi!;

            const injected = await web3FromSource(account?.meta.source);
            const isMimir = injected.name === 'mimir';

            const subs = [];
            for (let i = 0; i < subidentities.length; i++) {
                const sub = subidentities[i];
                if (sub.address.length == 0) {
                    continue;
                }
                try {
                    if (validateAddress(sub.address) == false)
                        continue;
                } catch (err) {
                    continue;
                }

                subs.push([sub.address, this.parseData(sub.alias)] as unknown as [AccountId32, Data]);
            }

            const tx = this.peopleApi.tx.identity.setSubs(subs);

            if (isMimir) {
                return tx.signAndSend(account.address, {
                    signer: injected.signer,
                    withSignedTransaction: true
                }).then((hash) => {
                    return res(hash.toString());
                }).catch((err) => {
                    return rej(err);
                });
            }

            return tx.signAndSend(account.address, { signer: injected.signer }, (result) => {
                if (result.status.isInBlock) {
                    return res(result.status.asInBlock.toString());
                }
            }).catch((err) => {
                return rej(err);
            });
        });
    }

    async runTeleport(account: InjectedAccountWithMeta, amount: string) {
        return new Promise(async (res, rej) => {
            if (!this.relayApi) await this.onRelayApiIsReady();
            this.relayApi = this.relayApi!;

            const injected = await web3FromSource(account?.meta.source);
            const isMimir = injected.name === 'mimir';

            const keyring = new Keyring();
            const publicKey = u8aToHex(keyring.decodeAddress(account.address));

            const tx = this.relayApi.tx.xcmPallet.limitedTeleportAssets(
                {
                    V3: {
                        parents: 0,
                        interior: {
                            X1: {
                                Parachain: 1004,
                            }
                        }
                    },
                },
                {
                    V3: {
                        parents: 0,
                        interior: {
                            X1: {
                                AccountId32: {
                                    id: publicKey,
                                }
                            }
                        }
                    }
                },
                {
                    V3: [
                        {
                            id: {
                                Concrete: {
                                    parents: 0,
                                    interior: 'Here'
                                }
                            },
                            fun: {
                                Fungible: amount
                            }
                        }
                    ]
                },
                0,
                "Unlimited"
            );

            if (isMimir) {
                return tx.signAndSend(account.address, {
                    signer: injected.signer,
                    withSignedTransaction: true
                }).then((hash) => {
                    return res(hash.toString());
                }).catch((err) => {
                    return rej(err);
                });
            }

            return tx.signAndSend(account.address, { signer: injected.signer }).then((hash) => {
                return res(hash.toString());
            }).catch((err) => {
                return rej(err);
            });
        });
    }

    runBatch(account: InjectedAccountWithMeta, identity: IdentityPolkaI) {
        return new Promise(async (res, rej) => {
            if (!this.peopleApi) await this.onPeopleApiIsReady();
            this.peopleApi = this.peopleApi!;

            const parsedIdentity = this.parseIdentity(identity);
            const setIdentity =
                this.peopleApi.tx.identity.setIdentity(parsedIdentity);

            const regIndex = this.getRegIndex();
            const maxFee = this.getMaxFee();

            if (!regIndex || !maxFee) return;
            const judgment = this.peopleApi.tx.identity.requestJudgement(
                regIndex,
                maxFee,
            );
            const injector = await web3FromSource(account.meta.source);
            const isMimir = injector.name === 'mimir';

            if (isMimir) {
                return this.peopleApi.tx.utility.batch([setIdentity, judgment])
                    .signAndSend(account.address, { signer: injector.signer, withSignedTransaction: true })
                    .then((hash) => {
                        return this.setIdentityRequestJudgement(account.address, hash.toString())
                            .then(() => res(hash.toString())).catch((err) => rej(err));
                    })
                    .catch((err) => rej(err));
            }

            return this.peopleApi.tx.utility
                .batch([setIdentity, judgment])
                .signAndSend(account.address, { signer: injector.signer })
                .then((hash) => {
                    return this.setIdentityRequestJudgement(account.address, hash.toString())
                        .then(() => res(hash.toString())).catch((err) => rej(err));
                }).catch((err) => rej(err));
        });
    }

    parseData = (
        s: string | null | undefined,
    ): { Raw: string } | { None: null } | { BlakeTwo256: string } => {
        if (s == null || s.length == 0) {
            return { None: null };
        }

        if (s.length > 32) {
            return { BlakeTwo256: blake2AsHex(s) };
        }

        return { Raw: s };
    };
    parseIdentity(
        identity: Partial<Record<IdentityKeyData, string | null>>,
    ): Record<
        IdentityKeyData,
        { Raw: string } | { None: null } | { BlakeTwo256: string } | null | undefined
    > {
        return {
            discord: this.parseData(identity.discord),
            display: this.parseData(identity.display),
            email: this.parseData(identity.email),
            github: this.parseData(identity.github),
            image: this.parseData(identity.image),
            legal: this.parseData(identity.legal),
            matrix: this.parseData(identity.matrix),
            pgpFingerprint: identity.pgpFingerprint as null,
            twitter: this.parseData(identity.twitter),
            web: this.parseData(identity.web),
            web_verified_at: this.parseData(identity.web_verified_at),
            web_verification_hash: this.parseData(identity.web_verification_hash),
        };
    }
}
