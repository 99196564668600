import { ApiRequestErrorI } from '@/interfaces/Api.interfaces';
import { IdentityI } from '@/interfaces/Identity.interface';
import Subidentity from '@/interfaces/Subidentity';
import { UserDataI } from '@/interfaces/User.interfaces';
import { SocketTypes } from '@/providers/ConnectWallet.controller';
import { identity } from '@polkadot/types/interfaces/definitions';
import request from './vendor/request';

type identity = Partial<{
    wallet_address: string;
    display_name: string;
    legal_name: string;
    email: string;
    web: string;
    twitter: string;
    discord: string;
    matrix: string;
    github: string;
    pgp: string;
    web_verification_hash: string | null;
    web_verified_at: string | null;
}>;

type getIdentity = {
    display_name: string;
    email: string;
    public_key: string;
    web_verification_hash: string | null;
    web_verified_at: string | null;
};

interface response {
    public_key: string;
    display_name: string;
    email: string;
    web_verification_hash: string | null;
    web_verified_at: string | null;
    update_at: string;
    create_at: string;
}
interface getVerified {
    verified: boolean;
}

export interface ApiIdentityErrorI extends ApiRequestErrorI {
    errors?: Partial<Record<keyof identity, string[]>>;
}

export default {
    postRequestJudgementHash: (provider: SocketTypes, publicKey: string, hash: string) =>
        request.postData<IdentityI>(`/api/identities/${provider}/${publicKey}/hash`, { judgement_request_hash: hash }),
    putIdentityData: (identity: UserDataI, provider: SocketTypes) =>
        request.putData<IdentityI>(`/api/identities/${provider}/${identity.public_key}`, identity),
    postIdentityData: (identity: UserDataI) =>
        request.postData<IdentityI>('/api/identities', identity),
    getSubidentities: (publicKey: string, provider: SocketTypes) =>
        request.getData<Subidentity[]>(`/api/identities/${provider}/${publicKey}/subidentities`),
    getDomain: (identityId: string) => request.get<getVerified>(`/api/identities/${identityId}/domain/dns`),
    getHtml: (identityId: string) => request.get<getVerified>(`/api/identities/${identityId}/domain/html`),
    getVerified: (identityId: string) =>
        request.get<getVerified>(`/api/identities/${identityId}/verified`),
    getjudgement: (identityId: string) =>
        request.postData<undefined>(`/api/identities/${identityId}/judgement`, {}),
    getIsRegistered: (publicKey: string) =>
        request.getData<getVerified>(`/api/identities/${publicKey}`),
    getUserProfile: (publicKey: string, provider: SocketTypes) =>
        request.getData<IdentityI>(`/api/identities/${provider}/${publicKey}`),
    postTeleport: (address: string, amount: string) =>
        request.post<{ call: string }>(`/api/teleport`, { address, amount }),
    getStatus: (network: string, publicKey: string) =>
        request.getData<IdentityI>(`/api/identities/${network}/${publicKey}`),
    getExtrinsic: (network: string, hash: string) =>
        request.get<any>(`/api/extrinsic/${network}/${hash}`),
};
