import lib from '@/lib';

const post = <T>(route: string, data: any) =>
    new Promise<T>((res, rej) => {
        lib.axios
            .post(route, data)
            .then(({ data }) => res(data))
            .catch((err) => rej(err));
    });

;
const postData = <T>(route: string, data: any) =>
    new Promise<T>((res, rej) => {
        lib.axios
            .post(route, data)
            .then(({ data }) => res(data.data))
            .catch((err) => rej(err));
    });


const putData = <T>(route: string, data: any) =>
    new Promise<T>((res, rej) => {
        lib.axios
            .put(route, data)
            .then(({ data }) => res(data.data))
            .catch((err) => rej(err));
    });
const put = <T>(route: string, data: any) =>
    new Promise<T>((res, rej) => {
        lib.axios
            .put(route, data)
            .then(({ data }) => res(data))
            .catch((err) => rej(err));
    });
const deleteSocial = <T>(route: string, data: any) =>
    new Promise<T>((res, rej) => {
        lib.axios
            .delete(route, data)
            .then(({ data }) => res(data))
            .catch((err) => rej(err));
    });

const get = <T>(route: string) =>
    new Promise<T>((res, rej) => {
        lib.axios
            .get(route)
            .then(({ data }) => res(data))
            .catch((err) => rej(err));
    });
const getWithAuthorization = <T>(route: string, token: string) =>
    new Promise<T>((res, rej) => {
        lib.axios
            .get(route, { headers: { Authorization: token } })
            .then(({ data }) => res(data))
            .catch((err) => rej(err));
    });
const getData = <T>(route: string) =>
    new Promise<T>((res, rej) => {
        lib.axios
            .get(route)
            .then(({ data }) => res(data.data))
            .catch((err) => rej(err));
    });

export default {
    post,
    postData,
    putData,
    put,
    get,
    getData,
    getWithAuthorization,
    deleteSocial,
};
