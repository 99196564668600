


export default {

    get(name: string) {
        const cookieArr = document.cookie.split(';');

        for (let i = 0; i < cookieArr.length; i++) {
            const cookie = cookieArr[i].trim();
            if (cookie.indexOf(name + '=') === 0) {
                const sub = atob(cookie.substring(name.length + 1));
                const value = Uint8Array.from({ length: sub.length }, (e, i) =>
                    sub.charCodeAt(i),
                );
                return new TextDecoder().decode(value);
            }
        }

        return null; 
    },
    delete(name: string) {
        document.cookie =
            name + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    },
    set(name: string, value: string) {
        const encodedValue = btoa(
            String.fromCharCode(...new TextEncoder().encode(value)),
        );
        document.cookie = `${name}=${encodedValue}`;
    },
};
